import React from "react";
import PropTypes from "prop-types";
import { useTheme, Typography, Grid, Hidden } from "@material-ui/core";
import styled from "styled-components";
//TODO delete these temp files
import HeroBackgroundXl from "../images/HeroBackground.jpg";
import HeroBackgroundLg from "../images/HeroBackground@lg.jpg";
import HeroBackgroundMd from "../images/HeroBackground@md.jpg";
import HeroBackgroundSm from "../images/HeroBackground@sm.jpg";
import HeroBackgroundXs from "../images/HeroBackground@xs.jpg";

import CopyrightSymbol from "./CopyrightSymbol";

function ContactHero(props) {
  return (
    <Wrapper container direction="column" justifyContent="center">
      <Typography variant="h1" align="center">
        Local. Specialized. Independent.
      </Typography>
    </Wrapper>
  );
}
const Wrapper = styled(Grid)`
  width: 100%;
  ${props => props.theme.breakpoints.up("xs")} {
    background: center / cover no-repeat url(${HeroBackgroundXs});
    height: 200px;
  }
  ${props => props.theme.breakpoints.up("sm")} {
    background: center / cover no-repeat url(${HeroBackgroundSm});
    height: 300px;
  }
  ${props => props.theme.breakpoints.up("md")} {
    background: center / cover no-repeat url(${HeroBackgroundMd});
    height: 350px;
  }
  ${props => props.theme.breakpoints.up("lg")} {
    background: center / cover no-repeat url(${HeroBackgroundLg});
    height: 488px;
  }
  ${props => props.theme.breakpoints.up("xl")} {
    background: center / cover no-repeat url(${HeroBackgroundXl});
    height: 488px;
  }
  .MuiTypography-h1 {
    font-size: 2.1rem;
    color: ${props => props.theme.palette.common.white};
    ${props => props.theme.breakpoints.up("md")} {
      font-size: 3.5rem;
    }
  }
`;
ContactHero.propTypes = {};

export default ContactHero;
