import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Hidden } from "@material-ui/core";
import Hero from "../components/Hero";
import Experience from "../components/Experience";
import LogoCarousel from "../components/LogoCarousel";
import CompetitivePressure from "../components/CompetitivePressure";
import Projects from "../components/Projects";
import Testimonials from "../components/Testimonials";
import People from "../components/People";
function Home(props) {
  return (
    <>
      <Hero />
      <Experience />
      <Hidden only="xs">
        <LogoCarousel />
      </Hidden>
      <CompetitivePressure />
      <Projects event={props.event} />
      <Testimonials />
      <People />
    </>
  );
}

Home.propTypes = {};

export default Home;
