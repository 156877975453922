import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Hidden } from "@material-ui/core";
import ContactHero from "../components/ContactHero";
import ContactInfo from "../components/ContactInfo";
import CopyrightNotice from "../components/CopyrightNotice";
import ContactFooter from "../components/ContactFooter";
function Contact(props) {
  return (
    <>
      <ContactHero />
      <ContactInfo />
      <CopyrightNotice />
      <Hidden xsDown>
        <ContactFooter />
      </Hidden>
    </>
  );
}

Contact.propTypes = {};

export default Contact;
