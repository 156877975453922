import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Typography, Slide } from "@material-ui/core";

import { Event } from "./Tracking";
function AnimatedName({
  name,
  containerWidth,
  containerWidth2,
  setOpen,
  data,
  copy,
  setModalData,
  ...props
}) {
  const [showLearnMore, setShowLearnMore] = React.useState(false);
  const handleAnimationStart = e => {
    document.getElementById(name).classList.add("brown");
    setShowLearnMore(true);
    return true;
  };
  const handleAnimationEnd = () => {
    return true;
  };
  const handleOnMouseOver = e => {
    const target = e.currentTarget.children[1].children["animated-element"];
    target.addEventListener("animationstart", handleAnimationStart, { once: true });
    target.addEventListener("animationend", handleAnimationEnd, { once: true });
    target.classList.add("slide-left");
  };
  const handleOnMouseLeave = e => {
    setShowLearnMore(false);

    e.currentTarget.children[1].children["animated-element"].classList.remove("slide-left");
    document.getElementById(name).classList.remove("brown");
  };

  const handleOnClick = e => {
    Event("People", "click", e.currentTarget.dataset.name);
    setModalData({
      copy: e.currentTarget.dataset.copy,
      name: e.currentTarget.dataset.name,
      image: e.currentTarget.dataset.image,
      position: e.currentTarget.dataset.position,
    });
    setOpen(true);
  };

  return (
    <ButtonWrapper
      onMouseOver={handleOnMouseOver}
      onMouseLeave={handleOnMouseLeave}
      data-copy={data?.copy}
      data-name={data?.name}
      data-image={data?.image}
      data-position={data?.position}
      onClick={handleOnClick}
    >
      <TypeWrapper
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <Typography variant="h5" id={name}>
          {name}
        </Typography>
        {!showLearnMore && <Typography variant="body1">{data?.position}</Typography>}
        {showLearnMore && <Typography variant="body1">Learn More &#12297;</Typography>}
      </TypeWrapper>
      <Wrapper containerWidth={containerWidth} containerWidth2={containerWidth2}>
        <SliderWrapper
          containerWidth={containerWidth}
          containerWidth2={containerWidth2}
          id="animated-element"
          //className="slide-left"
        />
      </Wrapper>
    </ButtonWrapper>
  );
}
const ButtonWrapper = styled.div`
  overflow: hidden;
`;
const TypeWrapper = styled(Grid)`
  padding-left: 2rem;
  position: absolute;
  z-index: 2;
  height: 92px;
  width: fit-content !important;

  .MuiTypography-h5.brown {
    color: rgba(55, 44, 46, 1);
  }
  .MuiTypography-h5 {
    color: ${props => props.theme.palette.common.white};
    margin: 0;
    z-index: 99;
    width: fit-content;
    font-size: 1.375rem;
  }
  .MuiTypography-body1 {
    color: ${props => props.theme.palette.common.white};
    margin: 0;
    text-transform: uppercase;
    font-style: italic;
    z-index: 100;
    width: fit-content;
    font-size: 0.625rem;
  }
`;
const SliderWrapper = styled.div`
  width: ${props => props.containerWidth2 - 8}px;
  height: 92px;
  left: 0px;
  top: 0;

  position: absolute;
  background-color: rgba(55, 44, 46, 1);
  ${props => props.theme.breakpoints.up("lg")} {
    width: ${props => props.containerWidth - 8}px;
  }
`;
const Wrapper = styled.div`
  width: ${props => props.containerWidth2}px;
  height: 92px;
  background-color: ${props => props.theme.palette.secondary.main};
  position: relative;
  ${props => props.theme.breakpoints.up("lg")} {
    width: ${props => props.containerWidth}px;
  }
  #animated-element {
    /* display: none; */
  }
  .slide-left {
    animation: slide-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  @keyframes slide-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-368px);
    }
  }
`;

AnimatedName.propTypes = {};

export default AnimatedName;
