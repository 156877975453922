import React from "react";
import styled from "styled-components";
import { Typography, Grid, Container } from "@material-ui/core";
import Quote from "./Quote";
export default function QuotesContainer() {
  return (
    <Wrapper
      component={Container}
      maxWidth="lg"
      className="quotes"
      container
      justifyContent="space-between"
      disableGutters
    >
      <Quote className="left-quote">
        <Typography variant="body2">
          They have proven themselves to be experts in real estate finance. Aspen uses the services
          of MillarForan because they take the time to understand our needs, then they execute with
          a mortgage process that consistently over-delivers.
        </Typography>
      </Quote>
      <Quote className="right-quote">
        <Typography variant="body2">
          Initial strategy and structuring of the financing transaction is fundamental to how the
          transaction will unfold. The team at MillarForan recognizes this and sees the issues
          coming around the next corner.
        </Typography>
      </Quote>
      <Quote className="left-quote">
        <Typography variant="body2">
          During my years in legal, political and real estate development pursuits, I have had many
          occasions to deal with professionals in the mortgage brokerage business. None have matched
          the professionalism, knowledge, efficiency, dedication and integrity that I have
          experienced in my dealings with Kris Heron, Neil Foran and their associates.
        </Typography>
      </Quote>
      <Quote className="right-quote">
        <Typography variant="body2">
          MillarForan provides transparency and objectivity to its service proposition which
          enhances our decision making and affords a higher degree of corporate governance for our
          Board members and stakeholders.
        </Typography>
      </Quote>
      <Quote className="left-quote">
        <Typography variant="body2">
          Wanted to let you know that the cash is officially in the bank and we are closed on the
          Re-fi! Also wanted to say THANK YOU a million times over for everything you do and for
          pushing so hard to get this deal done for us! So grateful to have you guys on our team.
        </Typography>
      </Quote>
      <Quote className="right-quote">
        <Typography variant="body2">
          Our sincere thanks to the entire MillarForan team. Another great piece of business with
          top-notch professionals!
        </Typography>
      </Quote>
      <Quote className="left-quote">
        <Typography variant="body2">
          Fantastic! Thanks for all your help! I have a very good feeling about this and the
          momentum it will create.
        </Typography>
      </Quote>
      <Quote className="right-quote">
        <Typography variant="body2">
          This was not an easy assignment at all. Thank you both for the excellent work, and the
          more than fair billing.
        </Typography>
      </Quote>
    </Wrapper>
  );
}
const Wrapper = styled(Grid)`
  .left-quote {
    margin-left: 0;
  }

  .right-quote {
    margin-right: 0;
  }

  .quotes {
    height: 100%;
    width: 100%;
    max-width: 1290px;
  }
`;
