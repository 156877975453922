import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

import {
  AppBar,
  Toolbar,
  IconButton,
  useTheme,
  Hidden,
  Button,
  Typography,
} from "@material-ui/core";
import { HashLink as Link } from "react-router-hash-link";

import MenuIcon from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import { motion, useViewportScroll } from "framer-motion";
import NavItem from "./NavItem";
import logo from "../images/NavLogo.svg";
import LogoReversed from "../images/NavLogoReversed.svg";

const options = {
  url: process.env.REACT_APP_CALENDLY_LINK,
};

const useStyles = makeStyles(theme => {
  return {
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: "auto",
      marginRight: "1.8rem",
      backgroundColor: "transparent",
    },
    menuIconButton: {
      backgroundColor: "transparent",
      colorPrimary: {
        "&hover": {
          backgroundColor: "transparent",
        },
      },
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },

    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  };
});

export default function MobileNavigation(props) {
  const theme = useTheme();
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const { scrollYProgress } = useViewportScroll();

  useEffect(() => {
    scrollYProgress.onChange(v => {
      setProgress(v * 100);
    });
  }, [scrollYProgress]);

  const [open, setOpen] = React.useState(false);
  const handleToggle = event => {
    // debugger;
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOpen(!open);
  };

  const handleOnClick = event => {
    props.scrollToTop();
  };
  return (
    <AppBar position="fixed">
      <div
        // className="progress-icon"

        style={{
          backgroundColor: theme.palette.common.white,
        }}
      >
        <motion.div
          id="motionPath"
          style={{
            position: "relative",

            // right: progress2 + "%",
            backgroundColor: theme.palette.primary.main,
            opacity: progress + 20 + "%",
            height: 2,
            borderRight: `5px solid ${theme.palette.secondary.main}`,
            zIndex: 999,
            width: progress + "%",
            // pathLength: "500px",
            // Reverse direction of line animation
          }}
        />
      </div>
      <StyledToolbar>
        {/* TODO Change Logo */}
        <NavItem activeClassName={"activeMobile"} href="/" onClick={handleOnClick}>
          <Logo showmobile={true} src={logo}></Logo>
        </NavItem>
        {/* <StyledBlock showmobile={true}>Avro Business Park</StyledBlock> */}
        <IconButton
          edge="start"
          className={classes.menuButton}
          classes={{ root: classes.menuIconButton }}
          // color="inherit"
          aria-label="open drawer"
          // open={handleToggle}
          onClick={handleToggle}
        >
          <MenuIcon style={{ width: "1.3em", height: "1.3em" }} />
        </IconButton>

        {/* <StyledBlock hidemobile={false}></StyledBlock> */}
      </StyledToolbar>
      {/* TODO close drawer when window is resized */}
      <Drawer
        open={open}
        // containerStyle={styles.navBar}
        PaperProps={{ component: StyledPaper }}
        onClick={handleToggle}
        onKeyDown={handleToggle}
        role="presentation"
        anchor="right"
        id="Drawer"
      >
        <Close style={{ alignSelf: "flex-end" }}></Close>
        <DrawerNavContainer id="drawer-nav-container">
          <NavItem href="/" onClick={props.handleNavClick} activeClassName={""}>
            <DrawerLogo src={LogoReversed}></DrawerLogo>
          </NavItem>

          <NavItem
            href="/#experience"
            handleOnClick={handleOnClick}
            activeClassName={"activeMobile"}
          >
            Experience
          </NavItem>
          <NavItem href="/#projects" handleOnClick={handleOnClick} activeClassName={"activeMobile"}>
            Projects
          </NavItem>
          <NavItem
            href="/#testimonials"
            handleOnClick={handleOnClick}
            activeClassName={"activeMobile"}
          >
            Testimonials
          </NavItem>
          <NavItem href="/#people" handleOnClick={handleOnClick} activeClassName={"activeMobile"}>
            People
          </NavItem>
          <NavItem href="/contact" handleOnClick={handleOnClick} activeClassName={"activeMobile"}>
            Contact
          </NavItem>

          {/* <a href="https://tandemmarketing.ca">WATCH VIDEO</a> */}
        </DrawerNavContainer>{" "}
      </Drawer>
    </AppBar>
  );
}
const NavItemText = styled(Link)`
  text-transform: uppercase;
`;
const StyledPaper = styled("div")`
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 26%),
    center 100% / 113% auto no-repeat url();
  padding-top: 2em;
  /* padding-left: 2em; */
  padding-right: 2em;
  width: 300px;
  @media (min-width: 480px) {
    width: 300px;
  }
  /* border-right: ${props => props.theme.mediumBlue} solid 5px; */
`;
const DrawerNavContainer = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 250px;
  padding: 0 0 3em 3em;
  flex: 1;
  @media (min-width: 480px) {
    width: 100%;
  }
  /* max-height: 500px; */
  a.active {
    background-color: transparent;
    text-decoration: none;
    &:before {
      /* display: inline-block;
      position:relative */
      content: "";
    }
  }

  .MuiButton-label {
    font-size: 1rem;
    font-style: normal;
  }
  a {
    padding: 0;
    background-color: transparent;
    text-decoration: none;
    margin: 0.5em;
    color: ${props => props.theme.palette.common.black};
    font-weight: ${props => props.theme.typography.fontWeightBold};
    &.activeMobile {
      color: ${props => props.theme.palette.secondary.main};
    }
    &:before {
      /* display: inline-block;
      position:relative */
      content: "";
    }
  }
`;
// const StyledAppBar = styled(AppBar)``;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.palette.primary.main};
  width: 100%;
  height: 58px;
  /* max-height: 55px; */
  /* margin-bottom: 25px; */

  z-index: 500;
  @media (min-width: 480px) {
    background-color: ${props => props.theme.darkBlue};
    height: 66px;
    /* max-height: 100px; */
  }
`;

const DrawerLogo = styled("img")`
  /* max-height: 15; */
  height: 3em;
  /* margin-bottom: 2em; */
`;
const Logo = styled("img")`
  max-height: 36px;
  width: 100%;
  height: auto;

  @media (min-width: 480px) {
    margin-top: 0.3em;
  }
`;
