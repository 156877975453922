import React, { Suspense, useEffect } from "react";

import ProgressiveImage from "react-progressive-image";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CircularProgress, Divider } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

function ImageWithPlaceholder({ image, placeholder, className, ...props }) {
  return (
    <>
      <StyledProgressiveImage delay={1000} src={image} placeholder={placeholder}>
        {(src, loading) => {
          return (
            <Image
              className={className}
              style={{ filter: loading ? "blur(5px)" : "blur(0)" }}
              src={src}
            />
          );
        }}
      </StyledProgressiveImage>
    </>
  );
}

const StyledProgressiveImage = styled(ProgressiveImage)`
  width: 100%;
  height: auto;
`;
const Image = styled.img`
  width: 100%;
  height: auto;
`;
Image.propTypes = {};

export default ImageWithPlaceholder;
