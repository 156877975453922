import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";

const font = "'Trebuchet MS', sans-serif";
const titleFont = "'geller-headline', serif";

const defaultTheme = responsiveFontSizes(
  createMuiTheme({
    maxWidth: "1920px",
    activeColor: "#6098AF",
    palette: {
      primary: {
        main: "rgba(55,44,46,1)",
        dark: "rgba(4, 62, 95, 1)",
        light: "rgba(192, 202, 200, 1)",
        active: "rgba(192, 202, 200, 0.54)", //Toggled
        hover: "rgba(168, 53, 58, 1)",
      },
      secondary: {
        main: "rgba(187,166,91,1)",
        dark: "#BDC93A",
        light: "rgba(248, 247, 244)",
        highlight: "#E6781F",
      },
      common: {
        lightBlack: "#1b1c1e",
        backgroundGrey: "rgba(245,245,245,1)",
      },
      action: {
        //This is a reference. You can use theme.palette.action.active etc.
        active: "rgba(192, 202, 200, 0.54)",
        hover: "rgba(37,95,126,1)",
        hoverOpacity: 0.1,
        selected: "rgba(192, 202, 200, 0.54)",
        selectedOpacity: 0.08,
        disabled: "rgba(0, 0, 0, 0.26)",
        disabledBackground: "rgba(0, 0, 0, 0.12)",
        disabledOpacity: 0.38,
        focus: "rgba(0, 0, 0, 0.12)",
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
      },
      text: {
        primary: "rgba(112, 125, 132, 1)",
        secondary: "rgba(0, 0, 0, 0.54)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)",
      },
    },
    typography: {
      htmlFontSize: 16,
      fontFamily: font,

      // fontSize: 12,
    },
  })
);

export const theme = responsiveFontSizes(
  createMuiTheme({
    ...defaultTheme,
    overrides: {
      // Style sheet name
      MuiContainer: {
        root: {
          paddingLeft: "2rem",
          paddingRight: "2rem",
        },
      },
      MuiIconButton: {
        root: {
          backgroundColor: defaultTheme.palette.primary.navy,
          // "&:hover": { backgroundColor: defaultTheme.palette.primary.red },
        },
      },
      MuiMenu: {
        paper: {
          backgroundColor: defaultTheme.palette.primary.main,
        },
      },
      MuiListItem: {
        root: {
          color: defaultTheme.palette.common.white,
          "&:focus": { backgroundColor: "transparent" },
        },

        button: {
          "&:hover": { backgroundColor: defaultTheme.palette.primary.slate, color: "white" },
        },
      },
      MuiTab: {
        root: {
          "&$selected": {
            backgroundColor: "#f5f5f5",
            color: "rgba(206,206,206,1)",
            "& .MuiTab-wrapper": {
              "& .MuiTypography-body1": {
                color: defaultTheme.palette.primary.slate,
              },
              "& .MuiTypography-h6": {
                color: defaultTheme.palette.primary.main,
              },
            },
          },
          "& .MuiTab-wrapper": {
            "& .MuiTypography-body1": {
              color: "rgba(206,206,206,1)",
            },
          },
          "&$wrapper": {
            color: "rgba(206,206,206,1)",
          },
        },
      },

      MuiAppBar: { colorSecondary: { backgroundColor: defaultTheme.palette.primary.main } },
      MuiButton: {
        // Name of the rule
        contained: {
          color: defaultTheme.palette.common.white,
          backgroundColor: "invisible",
        },
        // root: {
        //   borderRadius: 21,
        //   height: 42,
        // },
        label: {
          // Some CSS
          fontWeight: 100,
          letterSpacing: 1.28,
          // fontSize: ".8rem",
          // fontStyle: "italic",
        },
      },

      MuiCardActions: {
        root: {
          backgroundColor: defaultTheme.palette.primary.slate,
          color: defaultTheme.palette.common.white,
        },
      },
      MuiTypography: {
        // fontSize: 12,

        h1: {
          letterSpacing: 1.68,
          // color: defaultTheme.palette.primary.slate,
          fontSize: "2.5rem",
          fontFamily: titleFont,
          // fontWeight: 400,
          textTransform: "uppercase",
          [defaultTheme.breakpoints.up("sm")]: {
            fontSize: "2.0rem",
          },
          [defaultTheme.breakpoints.up("md")]: {
            fontSize: "2rem",
          },
          [defaultTheme.breakpoints.up("lg")]: {
            fontSize: "3.5rem",
          },
          [defaultTheme.breakpoints.up("xl")]: {
            fontSize: "2rem",
          },
        },
        h2: {
          fontFamily: titleFont,
          fontWeight: 700,
          textTransform: "uppercase",
        },
        h3: {
          // whiteSpace: "nowrap",
          fontSize: "1.3rem",
          textTransform: "uppercase",
          fontFamily: titleFont,
          fontWeight: 700,
          [defaultTheme.breakpoints.up("sm")]: {
            fontSize: "1.5rem",
          },
          [defaultTheme.breakpoints.up("md")]: {
            fontSize: "1.8rem",
          },
          [defaultTheme.breakpoints.up("lg")]: {
            fontSize: "2.0rem",
          },
        },
        h4: {
          fontSize: "1.3rem",
          fontFamily: titleFont,
          fontWeight: 700,
          textTransform: "uppercase",
        },
        h5: {
          // whiteSpace: "nowrap",
          fontSize: "1.5625rem",
          letterSpacing: 2,
          textTransform: "uppercase",
          fontFamily: titleFont,
          fontWeight: 100,
          [defaultTheme.breakpoints.up("sm")]: {
            fontSize: "1.2rem",
          },
          [defaultTheme.breakpoints.up("md")]: {
            fontSize: "1.6867rem",
          },
          [defaultTheme.breakpoints.up("lg")]: {
            fontSize: "1.5625rem",
            marginBottom: "2.5rem",
          },
        },
        h6: {
          fontFamily: titleFont,
          fontWeight: 700,
          // whiteSpace: "nowrap",
          // textTransform: "uppercase",
        },
        body1: {
          color: defaultTheme.palette.primary.main,
          maxWidth: defaultTheme.breakpoints.values.lg,
          lineHeight: "1.875rem",
          letterSpacing: 0.42,
          fontFamily: font,
        },
      },
      MuiInputBase: {
        input: {
          fontSize: "1rem",
          fontFamily: "Helvetica",
        },

        inputMultiline: {
          lineHeight: "1.5rem",
        },
      },
      MuiFormLabel: {
        root: {
          fontSize: "1.2rem",

          // color: "red",
        },
      },
    },
    typography: {
      htmlFontSize: 17,
      fontFamily: font,
    },
  })
  // { factor: 3 }
);
