import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Typography, Container } from "@material-ui/core";

import Headshot from "./Headshot";
import PersonModal from "../components/PersonModal";

import NeilForan from "../images/portraits/NeilForan.jpg";
import KrisHeron from "../images/portraits/KrisHeron.jpg";
import LauraKlein from "../images/portraits/LauraKlein.jpg";
import DebbieMooers from "../images/portraits/DebbieMooers.jpg";
import KellyFillion from "../images/portraits/KellyFillion.jpg";
import LibbyKeyser from "../images/portraits/LibbyKeyser.jpg";
import Decoration from "../images/footer-decoration.svg";

function People(props) {
  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState(null);
  //TODO get the breakpoint and use it to adjust alignment
  return (
    <Wrapper component="section" id="people">
      <Grid container justifyContent="space-between" spacing={1}>
        <Grid item xs={12} lg={4}>
          <Grid className="title" container direction="column" justifyContent="center">
            <Typography variant="h5">Our People</Typography>
            <Typography variant="body1">
              We have delivered billions of dollars worth of mortgage investments over multiple
              decades.
            </Typography>
          </Grid>
        </Grid>
        <Grid className="person" align="center" item xs={12} sm={6} lg={4}>
          <Headshot
            containerWidth="352"
            containerWidth2="332"
            name="Neil Foran"
            data={{ ...peopleObject.NeilForan }}
            setOpen={setOpen}
            setModalData={setModalData}
          />
        </Grid>
        <Grid className="person" align="center" item xs={12} sm={6} lg={4}>
          <Headshot
            containerWidth="352"
            containerWidth2="332"
            name="Kris Heron"
            data={{ ...peopleObject.KrisHeron }}
            setOpen={setOpen}
            setModalData={setModalData}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={1}>
        <Grid className="person" align="center" item xs={12} sm={6} lg={4}>
          <Headshot
            containerWidth="352"
            containerWidth2="332"
            name="Kelly Fillion"
            data={{ ...peopleObject.KellyFillion }}
            setOpen={setOpen}
            setModalData={setModalData}
          />
        </Grid>
        <Grid className="person" align="center" item xs={12} sm={6} lg={4}>
          <Headshot
            containerWidth="352"
            containerWidth2="332"
            name="Laura Klein"
            data={{ ...peopleObject.LauraKlein }}
            setOpen={setOpen}
            setModalData={setModalData}
          />
        </Grid>
        <Grid className="person" align="center" item xs={12} sm={6} lg={4} />
      </Grid>
      <PersonModal open={open} setOpen={setOpen} data={{ ...modalData }} />
      <img className="decoration" src={Decoration} alt="" role="presentation" />
    </Wrapper>
  );
}

const Wrapper = styled("div")`
  position: relative;
  /* background: url(${Decoration}) bottom -1px left calc(8rem - 12.8px) / auto no-repeat; */
  margin: 0 auto;
  max-width: 1088px;
  padding: 3rem 1rem;

  ${props => props.theme.breakpoints.up("md")} {
    padding: 5rem 5rem;
  }
  .title {
    height: 100%;
    ${props => props.theme.breakpoints.up("lg")} {
      width: 352px;
    }
  }
  .person {
    margin-bottom: 0.7rem;
  }
  p {
    margin-bottom: 2.5rem;
    ${props => props.theme.breakpoints.up("lg")} {
      max-width: 300px;
    }
  }
  .decoration {
    position: absolute;
    bottom: -1px;
    left: calc(1rem - 12.8px);

    ${props => props.theme.breakpoints.up("md")} {
      left: calc(5rem - 12.8px);
    }
  }
`;

const peopleObject = {
  NeilForan: {
    name: "Neil Foran",
    position: "Partner",
    image: NeilForan,
    copy: `Neil Foran is a Partner, Co-Founder and licensed Mortgage Broker of MillarForan. With more than 25 years of experience in commercial real estate, primarily in structuring mortgage debt, Neil has an earned reputation as a trusted advisor and passionate provider of professional service focused on client and service centric advocacy.
    
  After receiving his Bachelor’s degree in Economics from the University of Manitoba and working for several years in Winnipeg’s famed garment manufacturing industry, Neil then spent the next 5 years working within the Investment Sales Team of one of Canada’s largest commercial real estate brokerage firms. He relocated to Calgary in 1996 and joined the local office of a national commercial mortgage brokerage, initially as an Associate and then Partner. After selling his ownership interest in 2009, he immediately went on to become a Co-Founder and Partner of MillarForan, a team of dedicated professionals delivering a proven process and proven results through successful execution of creative and thoughtfully designed financing mandates.
   
  Neil works closely with the firms’ clients to educate them on current trends within the debt market, strategize on portfolio or property specific financing, and ultimately set the direction for entering the commercial mortgage market to successfully achieve their specific financing needs.
   
  Throughout his career, Neil has been invited to present on financing issues and trends to a number of private and industry organizations, such as the Alberta Appraisal Institute, NAIOP, and the Calgary and Saskatchewan Real Estate Forums.`,
  },
  KrisHeron: {
    name: "Kris Heron",
    position: "Partner",
    image: KrisHeron,
    copy: `Kris Heron is a partner and co-founder of MillarForan and started his profession in commercial mortgages in 2002. He has established deep relationships with lenders across Canada and MillarForan clients know Kris as a knowledgeable advisor and ardent borrower advocate.
  
Kris began his career in Saskatoon, servicing private and wealth management clients as a member of an advising team. During the next decade, Kris contributed to the growth of a national commercial mortgage firm before establishing MillarForan in 2010 with Rob Millar and Neil Foran. Kris has structured and negotiated commercial mortgages across a wide spectrum of type and class including land acquisition, interim construction financing, commercial and CMHC term business along with many creative and bespoke client offerings.

Kris holds a Bachelor of Commerce (Finance) from the University of Saskatchewan and has completed the Urban Land Economics program through the University of British Columbia’s Sauder School of Business.`,
  },
  LauraKlein: {
    name: "Laura Klein",
    position: "Executive assistant",
    image: LauraKlein,
    copy: `Laura has been in the commercial mortgage industry for 15+ years after holding numerous positions in office management and finance administration with a major resource company. Laura is a key part of MillarForan and works closely with all team members on support systems and administration. MillarForan clients appreciate and value Laura’s efficiency, accuracy and responsiveness.`,
  },
  DebbieMooers: {
    name: "Debbie Mooers",
    position: "Director,  Loan Underwriting",
    image: DebbieMooers,
    copy: `Debbie Mooers is the Director of Loan Underwriting at MillarForan.  She has over 20 years of commercial banking experience at one of the “Big 5” banks, and since 2003 has worked exclusively in commercial real estate lending for term debt and construction facilities.  Debbie has originated and closed transactions in multi-family, retail, industrial and office classes.  Most recently, Debbie gained in-depth experience with multi-family and CMHC while working at a trust company.  In addition to a strong background in credit analysis, Debbie seeks to apply a focused and thoughtful approach to each deal structure to ensure financing is placed that meets client’s needs.`,
  },
  KellyFillion: {
    name: "Kelly Fillion",
    position: "Vice President, Mortgage Closings",
    image: KellyFillion,
    copy: `Kelly Fillion joined the commercial real estate industry in 1999 after attaining her Finance Diploma from NAIT. She started with a Western Canadian financial institution working her way into loan administration. Subsequently, she gained nine years of valuable experience with a prominent loan servicing operation ($3 billion portfolio) culminating in a Senior Mortgage Closing Administrator position.

Kelly’s expertise and specialized skills enhance the closing and funding expectations that our customers have grown to expect and appreciate. Personable, pragmatic and thorough with details, Kelly sets a high standard for review of commitment letters, third party reports and insurance. Her knowledge in these areas combined with her strong lender relationships results in a smooth and timely loan closing process.`,
  },
};
People.propTypes = {};

export default People;
