import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Typography, Slide } from "@material-ui/core";

function Name({ containerWidth, containerWidth2, ...props }) {
  return (
    <Wrapper
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      alignContent="flex-start"
      containerWidth={containerWidth}
      containerWidth2={containerWidth2}
    >
      <Typography variant="h5">{props.name}</Typography>
      <Typography component="span" variant="body1">
        {props.position}
      </Typography>
    </Wrapper>
  );
}

const Wrapper = styled(Grid)`
  max-width: ${props => props.containerWidth2}px;
  height: 92px;
  border-right: 8px solid ${props => props.theme.palette.secondary.main};
  background-color: ${props => props.theme.palette.primary.main};
  padding-left: 2rem;
  ${props => props.theme.breakpoints.up("lg")} {
    width: ${props => props.containerWidth}px;
  }
  .MuiTypography-h5 {
    color: ${props => props.theme.palette.common.white};
    margin: 0;
    font-size: 1.375rem;
  }
  .MuiTypography-body1 {
    color: ${props => props.theme.palette.common.white};
    margin: 0;
    text-transform: uppercase;
    font-style: italic;
    font-size: 0.625rem;
  }
`;
Name.propTypes = {};

export default Name;
