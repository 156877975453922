import React from "react";
import styled from "styled-components";

import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
const NavItemText = styled("span")`
  text-transform: capitalize;
`;

//Styles to set the shape of the button set in nav.js
function NavItem(props) {
  return (
    <Link
      to={props.href}
      style={props.style}
      activeClassName={props.activeClassName}
      onClick={props.handleOnClick}
    >
      <NavItemText>{props.children}</NavItemText>
    </Link>
  );
}

export default NavItem;
