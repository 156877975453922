import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Typography } from "@material-ui/core";
function Footer(props) {
  const getCurrentYear = () => {
    const currentYear = new Date().getFullYear();
    return currentYear.toString();
  };
  const year = getCurrentYear();
  return (
    <Wrapper container justifyContent="center" alignItems="center">
      <Typography variant="body1">
        © MillarForan {year} | Suite 300, 407 Eighth Avenue S.W. Calgary, Alberta T2P 1E5 | Phone:
        403-262-9200
      </Typography>
    </Wrapper>
  );
}
const Wrapper = styled(Grid)`
  background-color: ${props => props.theme.palette.primary.main};
  padding: 0.5rem;
  .MuiTypography-body1 {
    font-size: smaller;
    color: ${props => props.theme.palette.secondary.main};
  }
`;
Footer.propTypes = {};

export default Footer;
