import React from "react";
import styled from "styled-components";
import LocationMap from "../images/LocationMap.jpg";
import { Grid, Typography } from "@material-ui/core";
import ImageWithPlacholder from "../components/ImageWithPlaceholder";

export default function ContactInfo() {
  return (
    <Wrapper component="section" container justifyContent="space-between">
      <Map
        item
        xs={12}
        md={7}
        component={ImageWithPlacholder}
        image={LocationMap}
        placeholder={LocationMap}
        alt=""
      />
      <Grid item xs={12} md={3}>
        <Grid
          id="address"
          container
          direction="column"
          justifyContent="center"
          alignContent="space-between"
        >
          <Typography variant="h5">Contact Us</Typography>
          <Typography variant="body1">(403) 262-9200</Typography>
          <Typography variant="body1">Our office is located at:</Typography>
          <Typography variant="body1">HSBC Bank Building</Typography>
          <Typography variant="body1">
            Suite 300, 407 8<sup>th</sup> Avenue SW
          </Typography>
          <Typography variant="body1">Calgary, Alberta T2P 1E5</Typography>
        </Grid>
      </Grid>
    </Wrapper>
  );
}
const Map = styled(Grid)``;
const Wrapper = styled(Grid)`
  padding: 1rem;
  ${props => props.theme.breakpoints.up("md")} {
    padding: 4rem;
  }
  & > div > div {
    height: 100%;
  }
  #address {
    ${props => props.theme.breakpoints.down("md")} {
      width: fit-content;
      margin: 0 auto;
      padding-top: 2rem;
      padding-bottom: 1rem;
      .MuiTypography-body1,
      .MuiTypography-h5 {
        text-align: center;
      }
    }
  }
`;
