import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import AnimatedName from "./AnimatedName";
import Name from "./Name";
function Headshot({
  image,
  name,
  containerWidth,
  containerWidth2,
  data,
  copy,
  setOpen,
  setModalData,
  ...props
}) {
  return (
    <Wrapper containerWidth={containerWidth} containerWidth2={containerWidth2}>
      <ImageWrapper
        imgSrc={data.image}
        containerWidth={containerWidth}
        containerWidth2={containerWidth2}
      />
      {data.copy && (
        <AnimatedName
          setOpen={setOpen}
          name={name}
          data={{ ...data }}
          copy={copy}
          setModalData={setModalData}
          containerWidth={containerWidth}
          containerWidth2={containerWidth2}
        />
      )}
      {!data.copy && (
        <Name
          name={data.name}
          position={data.position}
          containerWidth={containerWidth}
          containerWidth2={containerWidth2}
        />
      )}
    </Wrapper>
  );
}
const ImageWrapper = styled("div")`
  background: url(${props => props?.imgSrc}) center/cover no-repeat;
  height: 285px;
  width: ${props => props?.containerWidth2}px;
  ${props => props.theme.breakpoints.up("lg")} {
    width: ${props => props?.containerWidth}px;
  }
`;
const Wrapper = styled("div")`
  width: ${props => props.containerWidth2}px;
  ${props => props.theme.breakpoints.up("lg")} {
    min-width: ${props => props?.containerWidth}px;
    width: 100%;
  }
  img {
    width: ${props => props.containerWidth2}px;
    height: auto;
    ${props => props.theme.breakpoints.up("lg")} {
      min-width: ${props => props?.containerWidth}px;
    }
  }
`;
Headshot.propTypes = {};

export default Headshot;
