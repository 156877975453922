import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography, Grid, Button, Container, Box, Zoom, Dialog } from "@material-ui/core";
function ZoomedImage({ open, setOpen, image, imageAlt, ...props }) {
  function handleBackdropClick(event) {
    setOpen(false);
  }
  return (
    <>
      <Dialog maxWidth="lg" fullWidth={true} open={open} onClose={handleBackdropClick}>
        <BigImage src={image} alt={imageAlt} onClick={handleBackdropClick} loading="lazy" />
      </Dialog>
    </>
  );
}
const BigImage = styled("img")`
  width: 100%;
  height: auto;
  &:hover {
    cursor: zoom-out;
  }
`;
ZoomedImage.propTypes = {};

export default ZoomedImage;
