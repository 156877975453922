import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Typography } from "@material-ui/core";
import GalleryComponent from "./GalleryComponent";

import AspenLanding from "../images/projects/AspenLanding.jpg";
import AtlanticAveArtBlock from "../images/projects/AtlanticAveArtBlock.jpg";
import CambridgeManor from "../images/projects/CambridgeManor.jpg";
import ClearviewMarketSquare from "../images/projects/ClearviewMarketSquare.jpg";
// import EastfieldDistribution from "../images/projects/EastfieldDistribution.jpg";
// import EastLakeIndustrial from "../images/projects/EastLakeIndustrial.jpg";
import EightAvePlace from "../images/projects/EightAvePlace.jpg";
import FifthAve from "../images/projects/FifthAve.jpg";
import HyattRegencyHotel from "../images/projects/HyattRegencyHotel.jpg";
import JensenLakesCrossing from "../images/projects/JensenLakesCrossing.jpg";
import JensenLakesCrossingMontage from "../images/projects/JensenLakesCrossingMontage.jpg";
// import KanahoffCentre from "../images/projects/KanahoffCentre.jpg";
import KingsViewMarket from "../images/projects/KingsViewMarket.jpg";
import PalliserOne from "../images/projects/PalliserOne.jpg";
import StJohnsOnTenth from "../images/projects/StJohnsOnTenth.jpg";
import TheEdison from "../images/projects/TheEdison.jpg";
import TheGeorge from "../images/projects/TheGeorge.jpg";
import TheSpider from "../images/projects/TheSpider.jpg";
import NinthAve from "../images/projects/NinthAve.jpg";
import BannermanBlock from "../images/projects/BannermanBlock.jpg";
import TheAmpersandLobby from "../images/projects/TheAmpersandLobby.jpg";

import EastfieldDistributionCentre from "../images/projects/EastfieldDistributionCentre.jpg";
function Projects(props) {
  const data = [
    { url: NinthAve, alternativeText: "South Bank", height: "697", width: "1088" },
    { url: BannermanBlock, alternativeText: "Bannerman Block", height: "697", width: "1088" },
    { url: AspenLanding, alternativeText: "Aspen Landing", height: "697", width: "1088" },
    { url: TheSpider, alternativeText: "The Spider Site", height: "697", width: "1088" },

    {
      url: HyattRegencyHotel,
      alternativeText: "The Hyatt Regency Hotel",
      height: "697",
      width: "1088",
    },
    { url: TheAmpersandLobby, alternativeText: "The Ampersand", height: "697", width: "1088" },
    {
      url: AtlanticAveArtBlock,
      alternativeText: "Atlantic Ave Art Block",
      height: "697",
      width: "1088",
    },
    { url: CambridgeManor, alternativeText: "Cambridge Manor", height: "697", width: "1088" },
    {
      url: ClearviewMarketSquare,
      alternativeText: "Clearview Market Square",
      height: "697",
      width: "1088",
    },

    {
      url: EastfieldDistributionCentre,
      alternativeText: "Eastfield Distribution Centre",
      height: "697",
      width: "1088",
    },
    { url: EightAvePlace, alternativeText: "Eighth Avenue Place", height: "697", width: "1088" },
    { url: FifthAve, alternativeText: "444 5th Avenue SW", height: "697", width: "1088" },

    {
      url: JensenLakesCrossing,
      alternativeText: "Jensen Lakes Crossing",
      height: "697",
      width: "1088",
    },
    {
      url: JensenLakesCrossingMontage,
      alternativeText: "Jensen Lakes Crossing",
      height: "697",
      width: "1088",
    },

    { url: KingsViewMarket, alternativeText: "Kingsview Market", height: "697", width: "1088" },
    { url: PalliserOne, alternativeText: "Palliser One", height: "697", width: "1088" },
    { url: StJohnsOnTenth, alternativeText: "St John's On Tenth", height: "697", width: "1088" },
    { url: TheEdison, alternativeText: "The Edison", height: "697", width: "1088" },
    { url: TheGeorge, alternativeText: "The George", height: "697", width: "1088" },
  ];
  // const images = props.data.map((image, index) => {
  //   return (
  //     <img
  //       src={image.url}
  //       onDragStart={handleDragStart}
  //       onClick={props?.options?.handleOnClick ? props.options.handleOnClick : handleOnClick}
  //       alt={image.alternativeText}
  //       height={image.height}
  //       width={image.width}
  //       loading="lazy"
  //       role="presentation"
  //     />
  //   );
  // });
  return (
    <Wrapper component="section" id="projects">
      <Typography align="center" variant="h5">
        Projects
      </Typography>
      <GalleryComponent event={props.event} data={data}></GalleryComponent>
    </Wrapper>
  );
}
const Wrapper = styled(Grid)`
  padding: 3rem 1rem;

  ${props => props.theme.breakpoints.up("md")} {
    padding: 5rem;
  }
`;
Projects.propTypes = {};

export default Projects;
