import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTheme, Typography, Grid, Hidden } from "@material-ui/core";
import QuoteMarks from "../images/QuoteMarks.svg";

function Quote({ className, ...props }) {
  return (
    <QuoteWrapper className={className} item xs={12} md={6}>
      <QuoteMarkComponent src={QuoteMarks} alt="" role="presentation" />
      {props.children}
    </QuoteWrapper>
  );
}

const QuoteMarkComponent = styled.img`
  position: absolute;
  top: -5px;
  right: 56px;
`;

const QuoteWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  margin: 1rem 0;
  background: rgba(255, 255, 255, 0.2);
  position: relative;
  color: white;
  ${props => props.theme.breakpoints.up("md")} {
    margin: 1rem 0;
    max-width: 48.8%;
  }
`;
Quote.propTypes = {};

export default Quote;
