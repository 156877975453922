import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import {
  useTheme,
  Fab,
  useMediaQuery,
  Container,
  Button,
  Hidden,
  Typography,
  IconButton,
} from "@material-ui/core";
import { CloseIcon, ArrowUpward } from "@material-ui/icons";
import smoothscroll from "smoothscroll-polyfill";
import { Event, initGA } from "./components/Tracking";
import { convertColor, scrollToTop } from "./components/Functions";
import ScrollTop from "./components/ScrollTop";

import "./App.css";

import MainNavigation from "./components/MainNavigation";
import MobileNavigation from "./components/MobileNavigation";

import Home from "./pages/Home";
import Contact from "./pages/Contact";
import NoMatch from "./pages/NoMatch";

import Footer from "./components/Footer";

smoothscroll.polyfill();
function App(props) {
  const theme = useTheme();

  const showTextNav = useMediaQuery(theme.breakpoints.up("lg"));
  const locationObject = useLocation();
  useEffect(() => initGA("UA-12468652-1"), []);

  return (
    <>
      {showTextNav && (
        <MainNavigation
          event={Event}
          scrollToTop={scrollToTop}
          convertColor={convertColor}
          theme={theme}
        />
      )}
      {!showTextNav && (
        <MobileNavigation
          event={Event}
          scrollToTop={scrollToTop}
          convertColor={convertColor}
          theme={theme}
        />
      )}
      <ContentContainer disableGutters maxWidth="xl">
        <Switch>
          <Redirect from="/:url*(/+)" to={locationObject.pathname} />
          <Route exact path="/contact">
            <Contact event={Event} />
          </Route>
          <Route exact path="/">
            <Home event={Event} />
          </Route>
          <Route>
            <NoMatch />
          </Route>
        </Switch>
      </ContentContainer>
      <Footer />
      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <ArrowUpward />
        </Fab>
      </ScrollTop>
    </>
  );
}
const ContentContainer = styled(Container)`
  margin-top: 60px;

  ${props => props.theme.breakpoints.up("lg")} {
    margin-top: 0;
  }
`;
export default App;
