import React from "react";
import PropTypes from "prop-types";
import { NavLink as RouterNavLink } from "react-router-dom";
import { NavHashLink as Link } from "react-router-hash-link";
import { Button, AppBar, Toolbar, makeStyles, Grid, useScrollTrigger } from "@material-ui/core";

// import { motion, useViewportScroll } from "framer-motion";
import Logo from "../images/NavLogo.svg";
function MainNavigation(props) {
  const scrollWithOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const trigger = useScrollTrigger({ threshold: 50, disableHysteresis: true });
  const { scrollToTop } = props;
  const classes = useStyles();
  const Event = props.event;
  const handleNavClick = e => {
    // scrollToTop();
    Event("Nav", "click", e.currentTarget.dataset.name);
    scrollToTop();
  };
  const handleHashClick = e => {
    // scrollToTop();
    Event("Nav", "click", e.currentTarget.dataset.name);
  };
  return (
    <AppBar color={trigger ? "primary" : "transparent"} position="fixed" elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <div style={{ width: "fit-content" }}>
            <Button
              data-name={"Home"}
              className={classes.logoContainer}
              component={RouterNavLink}
              to="/"
              onClick={handleNavClick}
            >
              <img
                alt="Millar Foran Lending Leadership"
                className={classes.logo}
                src={Logo}
                loading="lazy"
              />
            </Button>
          </div>
          <div className={classes.navigation}>
            <div style={{ width: "fit-content" }}>
              {NavData.map((item, index) => {
                return (
                  <Button
                    data-name={item.label}
                    onClick={handleHashClick}
                    key={item.link}
                    component={Link}
                    smooth
                    className={
                      index === 0 ? [classes.navItemFirst, classes.navItem] : classes.navItem
                    }
                    activeClassName={classes.selected}
                    to={item.link}
                    variant="text"
                    alt={item.NavItemAltTag}
                    aria-controls="navigation"
                    scroll={el => scrollWithOffset(el)}
                  >
                    {item.label}
                  </Button>
                );
              })}
            </div>
          </div>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
const useStyles = makeStyles(theme => {
  return {
    navigation: {
      marginLeft: "auto",
      marginRight: 0,
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0",
      },
      [theme.breakpoints.up("md")]: {
        minWidth: 763,
        marginLeft: "auto",
      },
    },
    navItem: {
      margin: "0 10px",
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.white,
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: "transparent",
        color: theme.palette.secondary.main,
      },
    },
    navItemFirst: {
      marginLeft: 0,
      paddingLeft: 0,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: "transparent",
        color: theme.palette.secondary.main,
      },
    },
    logo: {
      width: 120,
      height: "auto",
    },
    logoContainer: {
      margin: "0 auto",
      width: "100%",
    },

    selected: {
      color: theme.palette.secondary.main,
    },
    toolbar: {
      margin: 0,
      padding: 0,
      maxWidth: "100vw",
      minHeight: 128,
      [theme.breakpoints.up("md")]: {
        marginLeft: "8.6875rem",
      },
    },
  };
});
const NavData = [
  {
    label: "Experience",
    link: "/#experience",
    NavItemAltTag: "Experience",
    NavItemIcon: null,
  },
  {
    label: "Projects",
    link: "/#projects",
    NavItemAltTag: "Projects",
    NavItemIcon: null,
  },
  {
    label: "Testimonials",
    link: "/#testimonials",
    NavItemAltTag: "Testimonials",
    NavItemIcon: null,
  },
  {
    label: "People",
    link: "/#people",
    NavItemAltTag: "People",
    NavItemIcon: null,
  },
  {
    label: "Contact",
    link: "/contact",
    NavItemAltTag: "Contact",
    NavItemIcon: null,
  },
];
MainNavigation.propTypes = { scrollToTop: PropTypes.func };

export default MainNavigation;
