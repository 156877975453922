import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Grid } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

import Carousel, { consts } from "react-elastic-carousel";

import Strafford from "../images/logos/strafford.png";
import MelorREIT from "../images/logos/Melor_REIT_Horizontal_2C.png";
import Melcor from "../images/logos/melcor-developments-logo.png";
import Matco from "../images/logos/MATCO_logo_black.png";
import Landstar from "../images/logos/landstar.png";
import GrandCentral from "../images/logos/GrandCentral_logo_colour.png";
import Certus from "../images/logos/certus.png";
import Aspen_Properties from "../images/logos/800px-Aspen_Properties.png";
function LogoCarousel(props) {
  const Logos = [
    Strafford,
    MelorREIT,
    Melcor,
    Matco,
    Aspen_Properties,
    Landstar,
    GrandCentral,
    Certus,
  ];
  const breakPoints = [
    { width: 600, itemsToShow: 4 },
    { width: 960, itemsToShow: 5 },
    { width: 1280, itemsToShow: 6 },
  ];
  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? <ChevronLeft /> : <ChevronRight />;
    return (
      <Button onClick={onClick} disabled={isEdge}>
        {pointer}
      </Button>
    );
  };
  return (
    <Wrapper>
      <Carousel
        enableAutoPlay={false}
        breakPoints={breakPoints}
        renderArrow={myArrow}
        pagination={false}
      >
        {Logos &&
          Logos.map((image, index) => {
            return (
              <Grid
                key={image}
                style={{ height: "100%" }}
                container
                justifyContent="center"
                alignItems="center"
              >
                <img draggable="false" className="client-logos" src={image} key={index} />
              </Grid>
            );
          })}
      </Carousel>
    </Wrapper>
  );
}
const Wrapper = styled.section`
  .rec-item-wrapper {
    height: 100%;
  }
  .rec-carousel-wrapper {
    margin-bottom: 3rem;
    ${props => props.theme.breakpoints.up("md")} {
      margin-bottom: 5rem;
    }
  }
  .client-logos {
    max-height: 90px;
    max-width: 130px;
    width: auto;
    height: auto;
  }
`;
LogoCarousel.propTypes = {};

export default LogoCarousel;
