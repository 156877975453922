import React from "react";

import NoMatchHero from "../components/NoMatchHero";

function Home(props) {
  return (
    <>
      <NoMatchHero />
    </>
  );
}

export default Home;
