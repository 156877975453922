import React from "react";
import styled from "styled-components";

export default function CopyrightSymbol({ size, color, ...props }) {
  return <Wrapper>®</Wrapper>;
}

const Wrapper = styled.span`
  height: 100%;

  font-size: inherit;
  margin-bottom: auto;
  color: inherit;
`;
