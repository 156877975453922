import { theme } from "../Theme";

export const convertColor = color => {
  if (color === "Primary") {
    return theme.palette.primary.main;
  }
  if (color === "Secondary") {
    return theme.palette.secondary.main;
  }
  if (color === "PrimaryLight") {
    return theme.palette.primary.light;
  }
  if (color === "SecondaryLight") {
    return theme.palette.secondary.light;
  }
  if (color === "PrimaryDark") {
    return theme.palette.primary.dark;
  }
  if (color === "SecondaryDark") {
    return theme.palette.secondary.dark;
  }

  return theme.palette.common.white;
};
export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "auto" });
};
