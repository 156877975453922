import React, { useState } from "react";
import styled from "styled-components";
import { Container, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

// import ImageGallery from "react-alice-carousel";
// import "react-alice-carousel/lib/alice-carousel.css";
import ZoomedImage from "./ZoomedImage";

export default function Gallery(props) {
  const theme = useTheme();
  const showThumbnails = useMediaQuery(theme.breakpoints.up("lg"));
  const thumbPosition = useMediaQuery(theme.breakpoints.up("md"));
  const Event = props.event;
  let position = "bottom";

  if (thumbPosition) {
    position = "bottom";
  }
  if (!thumbPosition) {
    position = "right";
  }

  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState();
  const [altImage, setAltImage] = React.useState();

  // react image gallery format
  const images = props.data.map((image, index) => {
    return {
      original: `${image.url}`,
      thumbnail: `${image.url}`,
      originalAlt: image.alternativeText,
      thumbnailAlt: image.alternativeText,
      description: image.alternativeText,
      originalHeight: image.height,
      originalWidth: image.width,
      loading: "lazy",
      thumbnailClass: "MF-thumb",
    };
  });

  const handleClick = e => {
    if (!showThumbnails) {
      return;
    }

    setOpen(!open);
    setImage(e.currentTarget.childNodes[0].currentSrc);
    setAltImage(e.currentTarget.innerText);
    Event("Project", "click", e.currentTarget.innerText);
    return true;
    // if (!isFullScreen) {
    //   imageRef.current.fullScreen();
    //   setFullScreen(true);
    // }
  };
  return (
    <>
      <GalleryContainer maxWidth="lg" id="GalleryContainer" showThumbnails={showThumbnails}>
        {props.children}
        <ImageGallery
          showFullscreenButton={false}
          showPlayButton={false}
          onClick={handleClick}
          showThumbnails={showThumbnails}
          slideOnThumbnailOver={false}
          thumbnailLoading="lazy"
          // showNav={false}
          items={images}
          lazyLoad={true}
          disableSwipe={false}
        />
      </GalleryContainer>
      <ZoomedImage open={open} setOpen={setOpen} image={image} imageAlt={altImage} />
    </>
  );
}

const TitleContainer = styled(Grid)`
  color: ${props => props.theme.palette.common.white};
  background-color: ${props => props.theme.palette.primary.main};
  padding: 0.5rem 1rem;
`;

const GalleryContainer = styled(Container)`
  position: relative;
  width: 100%;
  margin-top: 2rem;
  padding: 0;
  height: auto;

  /* ${props => props.theme.breakpoints.up("sm")} {
      margin-left: 0.5rem;
    } */
  ${props => props.theme.breakpoints.up("md")} {
    /* padding: 0 1.5rem; */
  }
  img.image-gallery-image {
    cursor: ${props => (props.showThumbnails ? "zoom-in" : "initial")};
  }
  .MF-thumb {
    width: ${props => (props.showThumbnails ? "20%" : "100%")};
    height: auto;
  }
  .image-gallery-thumbnails-wrapper {
  }
  .image-gallery-slide {
    margin-bottom: 2rem;
  }
  .image-gallery-image {
    object-fit: cover !important;
    height: auto;
  }
  .image-gallery-right-nav {
    right: 0;
    height: 100%;
    width: 130px;
    text-align: right;
  }
  .image-gallery-left-nav {
    right: 0;
    height: 100%;
    width: 130px;
    text-align: left;
  }
  .image-gallery-description {
    color: ${props => props.theme.palette.common.white};
    background-color: ${props => props.theme.palette.primary.main};
    bottom: -2rem;
    width: 100%;
    text-align: left;
    font-style: italic;
    text-transform: capitalize;
    padding: 0.7rem 2rem;
    box-sizing: border-box;
  }
`;
