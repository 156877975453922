import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography, Grid, useMediaQuery, useTheme } from "@material-ui/core";
function CompetitivePressure(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <Wrapper
      container
      component="section"
      direction="column"
      justifyContent="center"
      alignItems={matches ? "flex-start" : "center"}
    >
      <Typography variant="h5">
        Competitive Pressure<sup>®</sup>
      </Typography>
      <Typography variant="body1">
        The MillarForan team is well known in the Canadian lending community for its exceptional
        underwriting, clarity and transparency. Delivering successful commercial finance solutions
        for over five plus decades has allowed MillarForan to build trust among lenders. The
        MillarForan process understands lender realities, and together with the firm’s established
        skill set, generates approvals and load closings with a higher quality and proficiency than
        conventional lending options.
      </Typography>
    </Wrapper>
  );
}

const Wrapper = styled(Grid)`
  color: ${props => props.theme.palette.common.white};
  background-color: ${props => props.theme.palette.primary.main};

  padding-left: 1rem;
  padding-right: 1rem;
  min-height: 551px;
  text-align: left;

  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 376px;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  ${props => props.theme.breakpoints.up("md")} {
  }
  ${props => props.theme.breakpoints.up("lg")} {
    text-align: center;
    min-height: 376px;
    padding-left: 139px;
    padding-right: 139px;
  }
  ${props => props.theme.breakpoints.up("xl")} {
  }
  .MuiTypography-body1 {
    color: ${props => props.theme.palette.common.white};
  }
  h5 {
    text-align: left;

    padding-bottom: 1.5rem;
    color: ${props => props.theme.palette.secondary.main};
    ${props => props.theme.breakpoints.up("xs")} {
      width: 80%;
    }
    ${props => props.theme.breakpoints.up("sm")} {
      text-align: center;
    }
  }
`;
CompetitivePressure.propTypes = {};

export default CompetitivePressure;
