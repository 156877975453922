import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Typography, Container } from "@material-ui/core";

import Carousel, { consts } from "react-elastic-carousel";

import ImageWithPlacholder from "./ImageWithPlaceholder";
import ExperienceImage from "../images/_S3A5915_REV.jpg";

function Experience(props) {
  return (
    <SectionWrapper disableGutters maxWidth="lg">
      <Wrapper
        component="section"
        id="experience"
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm={4} md={6}>
          <img src={ExperienceImage} className="main-image" alt="Millar Forran Experience" />
        </Grid>
        <Grid item xs={12} sm={7} md={6}>
          <Typography variant="h5">Our Experience</Typography>
          <Typography variant="body1">
            We are leaders in commercial real estate finance. Throughout Western Canada’s prairie
            provinces, you will find MillarForan across every asset category. For decades,
            MillarForan has brought more to the table; we understand the specific needs of each
            borrower and structure value-added deals with full market knowledge. Experience has
            taught us to be excellent in every aspect of commercial mortgages, including negotiating
            the commitment to managing the close. In short, we create Competitive Pressure® ensuring
            the interests of the borrower are fully satisfied. MillarForan is in the mortgage market
            every day, building and using its talent for the benefit of the borrowing customer.
          </Typography>
        </Grid>
      </Wrapper>
    </SectionWrapper>
  );
}

const SectionWrapper = styled(Container)`
  padding: 0;
  ${props => props.theme.breakpoints.up("sm")} {
    padding: 0 3rem 2rem;
  }
  ${props => props.theme.breakpoints.up("md")} {
    padding: 0 5rem 2rem;
  }
`;

const Wrapper = styled(Grid)`
  padding: 2rem 1rem 2rem;

  ${props => props.theme.breakpoints.down("sm")} {
    /* padding-top: initial;
    padding-left: 1rem;
    padding-right: 1rem; */
    /* padding-bottom: 1rem; */
  }
  ${props => props.theme.breakpoints.up("sm")} {
    /* padding-top: initial;
    padding-left: 1rem;
    padding-right: 1rem; */
    padding: initial;
  }
  ${props => props.theme.breakpoints.up("lg")} {
    /* padding: 6rem 139px 4rem; */
    min-height: 638px;
  }

  ${props => props.theme.breakpoints.up("xl")} {
  }

  h5 {
    padding-top: 3rem;
    padding-bottom: 1.5rem;
    ${props => props.theme.breakpoints.up("sm")} {
      padding-top: 4.25rem;
    }
  }

  img.main-image {
    width: 100%;
    height: auto;
    ${props => props.theme.breakpoints.up("sm")} {
      width: 100%;
      max-width: 315px;
      height: auto;
    }
    ${props => props.theme.breakpoints.up("md")} {
    }
    ${props => props.theme.breakpoints.up("lg")} {
      padding-right: 1rem;
      width: 100%;
      max-width: 444px;
      height: auto;
    }
    ${props => props.theme.breakpoints.up("xl")} {
    }
  }
`;

Experience.propTypes = {};

export default Experience;
