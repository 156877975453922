import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ImageGallery from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Typography, Grid } from "@material-ui/core";

import ImageWithPlacholder from "./ImageWithPlaceholder";

import Background from "../images/GettyImages-640905870_BW.jpg";

import QuotesContainer from "./QuotesContainer";
function Testimonials(props) {
  return (
    <Wrapper
      container
      direction="column"
      wrap="nowrap"
      justifyContent="center"
      alignItems="center"
      id="testimonials"
      component="section"
    >
      <Typography align="left" variant="h5">
        PROVEN TEAM, PROVEN PROCESS, PROVEN RESULTS<sup>®</sup>
      </Typography>
      <Typography align="left" variant="body1">
        Here’s what our clients say
      </Typography>

      <QuotesContainer />
    </Wrapper>
  );
}

const Wrapper = styled(Grid)`
  width: 100%;
  background: bottom / cover no-repeat url(${Background});
  padding: 3rem 1rem;

  ${props => props.theme.breakpoints.up("md")} {
    padding: 5rem 5rem;
  }

  .MuiTypography-h5 {
    color: ${props => props.theme.palette.secondary.main};
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    max-width: 1280px;
    ${props => props.theme.breakpoints.up("sm")} {
    }
  }

  .MuiTypography-body1 {
    text-transform: uppercase;
    font-style: italic;
    font-family: unset;
    color: ${props => props.theme.palette.common.white};
    width: 100%;
    margin-bottom: 2.5rem;
  }
`;
Testimonials.propTypes = {};

export default Testimonials;
