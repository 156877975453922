import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Dialog, Grid, Typography, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import RenderMarkdown from "./RenderMarkdown";
function PersonModal({ open, setOpen, data, ...props }) {
  function handleBackdropClick(event) {
    setOpen(false);
  }
  return (
    <>
      <Wrapper maxWidth="lg" fullWidth={false} open={open} onClose={handleBackdropClick}>
        <IconButton
          aria-label="close"
          color="primary"
          // onTouchTap={handleBackdropClick}
          onClick={handleBackdropClick}
        >
          <CloseIcon />
        </IconButton>
        <Grid className="main-container" wrap="wrap" container justifyContent="space-evenly">
          <Grid item xs={12} md={3}>
            <Grid className="bio" container direction="column">
              <ImageWrapper id="bio-photo" imgSrc={data.image} />

              <Typography variant="h5">{data.name}</Typography>
              <Typography className="position" variant="body1">
                {data.position}
              </Typography>
            </Grid>
          </Grid>
          <Grid className="bio-copy" item xs={12} md={7}>
            <RenderMarkdown body={data.copy} />
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
}
const ImageWrapper = styled("div")`
  background: url(${props => props?.imgSrc}) center/cover no-repeat;
  height: 168px;

  width: 168px;
  ${props => props.theme.breakpoints.up("lg")} {
    width: 168px;
  }
  ${props => props.theme.breakpoints.only("xs")} {
    display: none;
  }
`;
const Wrapper = styled(Dialog)`
  position: relative;

  .MuiIconButton-root {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .main-container {
    padding: 0.5rem;
    ${props => props.theme.breakpoints.up("sm")} {
      padding: 1rem;
    }

    ${props => props.theme.breakpoints.up("md")} {
      padding: 4rem;
    }
    div.bio > p.position {
      text-transform: uppercase;
      font-style: italic;
      color: #372c2e;
      font-size: smaller;
      margin-bottom: 1rem;
    }
    h5 {
      margin: 0;
      color: #372c2e;
    }

    div.bio-copy > p {
      margin-bottom: 1rem;
    }
  }
`;
PersonModal.propTypes = {};

export default PersonModal;
