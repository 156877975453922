import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTheme, Typography, Grid, Hidden } from "@material-ui/core";

import ImageWithPlacholder from "./ImageWithPlaceholder";

import HeroBackgroundXl from "../images/HeroBackground.jpg";
import HeroBackgroundLg from "../images/HeroBackground@lg.jpg";
import HeroBackgroundMd from "../images/HeroBackground@md.jpg";
import HeroBackgroundSm from "../images/HeroBackground@sm.jpg";
import HeroBackgroundXs from "../images/HeroBackground@xs.jpg";
import MontageXs from "../images/HeroMontage@xs.jpg";
import Montage from "../images/HeroMontage.jpg";

function Hero(props) {
  //   const theme = useTheme();

  return (
    <>
      <Hidden lgUp>
        <MontageWrapper>
          {/* <StyledMontage image={Montage} placeholder={MontageXs} alt="" /> */}
          <StyledImg src={Montage} alt="" role="presentation" />
        </MontageWrapper>
      </Hidden>
      <Wrapper container direction="column" justifyContent="center" alignItems="flex-start">
        <Typography variant="h1">
          Lending
          <br />
          Leadership<sup>®</sup>
        </Typography>
        <Typography variant="body1">
          Over multiple decades, we have delivered billions of dollars in mortgage investments.
        </Typography>
        <Hidden mdDown>
          <MontageWrapper>
            <StyledImg src={Montage} alt="" role="presentation" />
          </MontageWrapper>
        </Hidden>
      </Wrapper>
    </>
  );
}
const MontageWrapper = styled.div`
  width: 100vw;
  position: relative;
  ${props => props.theme.breakpoints.up("xs")} {
  }
  ${props => props.theme.breakpoints.up("sm")} {
  }
  ${props => props.theme.breakpoints.up("md")} {
    position: absolute;
    max-width: 600px;
    top: 180px;
    right: 0;
  }
  ${props => props.theme.breakpoints.up("lg")} {
    position: absolute;
    max-width: 763px;
    top: 126px;
    right: 0;
  }
  ${props => props.theme.breakpoints.up("xl")} {
  }
`;
const StyledMontage = styled(ImageWithPlacholder)`
  width: 100%;
  height: auto;
`;
const StyledImg = styled("img")`
  width: 100%;
  height: auto;
`;
const Wrapper = styled(Grid)`
  padding-left: 1rem;
  width: 100%;

  ${props => props.theme.breakpoints.up("xs")} {
    background: center / cover no-repeat url(${HeroBackgroundXs});
    height: 405px;
  }

  ${props => props.theme.breakpoints.up("sm")} {
    background: center / cover no-repeat url(${HeroBackgroundSm});
    height: 375px;
  }

  ${props => props.theme.breakpoints.up("md")} {
    padding-left: 5rem;
    background: center / cover no-repeat url(${HeroBackgroundMd});
    height: 600px;
  }

  ${props => props.theme.breakpoints.up("lg")} {
    padding-left: 8.6875rem;
    background: center / cover no-repeat url(${HeroBackgroundLg});
    height: 685px;
  }

  ${props => props.theme.breakpoints.up("xl")} {
    background: center / cover no-repeat url(${HeroBackgroundXl});
    height: 685px;
  }

  .MuiTypography-h1 {
    color: ${props => props.theme.palette.common.white};
    border-bottom: solid 2px ${props => props.theme.palette.secondary.main};
    /* font-size: 56px; */
    max-width: 353px;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    /* font-size: 4rem; */
  }

  .MuiTypography-body1 {
    color: ${props => props.theme.palette.common.white};
    max-width: 353px;
  }
`;
Hero.propTypes = {};

export default Hero;
