import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography, Grid, useMediaQuery, useTheme } from "@material-ui/core";
function CopyrightNotice(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <Wrapper
      container
      component="section"
      direction="column"
      justifyContent="center"
      alignItems={matches ? "flex-start" : "center"}
    >
      <Typography variant="h5">Millarforan</Typography>
      <Typography variant="body1">
        This website and its contents are subject to copyright, © 2021, all rights reserved. Lending
        Leadership; Proven Team. Proven Process. Proven Results.; Something More.; Competitive
        Pressure.; are registered trademarks in Canada. MillarForan; millarforan; and all associated
        graphics, designs and logos are trademarks of MillarForan, all rights reserved.
      </Typography>
    </Wrapper>
  );
}

const Wrapper = styled(Grid)`
  color: ${props => props.theme.palette.common.white};
  background-color: ${props => props.theme.palette.primary.main};
  padding: 3rem 1rem;

  text-align: left;

  ${props => props.theme.breakpoints.up("sm")} {
    padding: 5rem;
  }
  ${props => props.theme.breakpoints.up("md")} {
  }
  ${props => props.theme.breakpoints.up("lg")} {
    text-align: center;
    padding: 5rem;
  }
  ${props => props.theme.breakpoints.up("xl")} {
  }
  .MuiTypography-body1 {
    color: ${props => props.theme.palette.common.white};
  }
  h5 {
    text-align: left;

    padding-bottom: 1.5rem;
    color: ${props => props.theme.palette.secondary.main};
    ${props => props.theme.breakpoints.up("xs")} {
      width: 80%;
    }
    ${props => props.theme.breakpoints.up("sm")} {
      padding-bottom: 0;
      text-align: center;
    }
  }
`;
CopyrightNotice.propTypes = {};

export default CopyrightNotice;
