import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography, Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import ReactMarkdown from "react-markdown";

function RenderMarkdown(props) {
  const variant = props?.variant;
  const align = props?.align;
  const paragraph = props?.paragraph;
  const listColumns = props?.listColumns;
  const style = props?.style;
  const markerstyle = props.markerstyle;
  const renderTypography = props => {
    return (
      <Typography
        className="md"
        style={style}
        paragraph={paragraph}
        align={align}
        variant={props.node.tagName === "p" ? "body1" : props.node.tagName}
      >
        {props.children.map((child, index) => {
          return child;
        })}
      </Typography>
    );
  };
  const renderButton = props => {
    return (
      <Button className="md" style={style} component={NavLink} to={props.href} variant="contained">
        {props.children}
      </Button>
    );
  };
  const defaultOrderedList = props => {
    return (
      <ol
        className="md"
        style={
          listColumns
            ? { ...props.style, columns: `${listColumns} auto` }
            : { ...props.style, columns: "1 auto" }
        }
      >
        {props.children.map((child, index) => {
          return <li key={child}>{child}</li>;
        })}
      </ol>
    );
  };
  const defaultUnorderedList = props => {
    return (
      <StyledUnorderedList
        className="md"
        style={listColumns ? { columns: `${listColumns} auto` } : { columns: "1 auto" }}
        markerstyle={markerstyle}
      >
        {props.children.map((child, index) => {
          //   return child;

          return (
            child?.props?.children[0] && (
              <li key={child?.props?.children[0]}>
                <Typography
                  className="md"
                  style={style}
                  variant={variant ? variant : "body1"}
                  align={align ? align : "left"}
                >
                  {child?.props?.children[0]}
                </Typography>
              </li>
            )
          );
        })}
      </StyledUnorderedList>
    );
  };

  return (
    <ReactMarkdown
      components={{
        p: renderTypography,
        h1: renderTypography,
        h2: renderTypography,
        h3: renderTypography,
        h4: renderTypography,
        h5: renderTypography,
        h6: renderTypography,
        ol: props.orderedListComp ? props.orderedListComp : defaultOrderedList,
        ul: defaultUnorderedList,
        a: renderButton,
      }}
    >
      {props.body}
    </ReactMarkdown>
  );
}

const StyledUnorderedList = styled.ul`
  padding-inline-start: 1.1rem;

  li {
    ::marker {
      color: ${props =>
        props?.markerstyle?.color ? props.markerstyle.color : props.theme.palette.text.primary};
    }
  }
  p {
    color: inherit;
  }
`;

RenderMarkdown.propTypes = {};

export default RenderMarkdown;
