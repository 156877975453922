import React from "react";
import styled from "styled-components";
import { Typography, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import FooterImage from "../images/ContactFooter.jpg";
function ContactFooter(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <Wrapper
      container
      component="section"
      direction="column"
      justifyContent="center"
      alignItems={matches ? "flex-start" : "center"}
    >
      <Typography align="center" variant="h3">
        Something More.<sup>®</sup>
      </Typography>
    </Wrapper>
  );
}

const Wrapper = styled(Grid)`
  color: ${props => props.theme.palette.common.white};
  background: center / cover url(${FooterImage});
  padding-left: 1rem;
  padding-right: 1rem;
  min-height: 551px;
  text-align: left;

  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 376px;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  ${props => props.theme.breakpoints.up("md")} {
  }
  ${props => props.theme.breakpoints.up("lg")} {
    text-align: center;
    min-height: 376px;
    padding-left: 139px;
    padding-right: 139px;
  }
  ${props => props.theme.breakpoints.up("xl")} {
  }

  h2 {
    padding-bottom: 1.5rem;
    color: ${props => props.theme.palette.common.white};
  }
`;

export default ContactFooter;
